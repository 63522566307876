import { useState, useContext, useEffect } from "react";
import styles from "../styles/Dialog.module.css";

type LockdropResultModalProps = {
  open: boolean;
  closeDialog: () => {};
  type: string;
  msg: string;
};
const LockdropResultModal = ({
  open,
  closeDialog,
  type,
  msg,
  
}: LockdropResultModalProps) => {
  //console.log("fetch msg", msg, type, open);

  return (
    <>
      {open && (
        <div className={styles.backdrop}>
          <div
            className={`${styles.modalBody} `}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "100px",
                paddingBottom: "70px",
                // gap:"20px",
                flexDirection: "column",
              }}
            >
              <img
                src={
                  type === "fail"
                    ? "/images/warning-logo@2x.webp"
                    : "/images/icon-success@2x.webp"
                }
                alt="icon"
                width={90}
                height={80}
              />

              {type === "fail" ? (
                <p
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    marginTop: "40px",
                    marginBottom: "20px",
                  }}
                >
                  {msg}
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    display: "flex",
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                >
                  {msg}
                </p>
              )}
            </div>
            <div className={`${styles.bottomModal} `}>
              <button
                onClick={() => closeDialog()}
                className={`${styles.closeButton} `}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default LockdropResultModal;
