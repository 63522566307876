import styles from "../styles/footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p className={styles.copyrightText}>
        Copyright © <span className={styles.tMinus}>T-MINUS</span>.
      </p>
      <a href="https://twitter.com/t_minus_io" target="_blank" rel="noreferrer">
        <img src="/images/twitter.svg" alt="twitter" width={22} height={20} />
      </a>
    </footer>
  );
};

export default Footer;
