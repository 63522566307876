import styles from "../styles/Navbar.module.css";
import useConnectWalletStore from "../Store/ConnectWallet";
import { formatAddress } from "../utils/helper";
const Navbar = () => {
  const walletAddress = useConnectWalletStore((state) => state.walletAddress);
  const setWalletAddress = useConnectWalletStore(
    (state) => state.setWalletAddress
  );
  const setConnected = useConnectWalletStore((state) => state.setConnected);
  const connected = useConnectWalletStore((state) => state.connected);

  const disConnectWalletHandler = () => {
    setWalletAddress("");
    setConnected(false);
    console.log("disconnect");
    localStorage.removeItem("walletProvider");
  };
  return (
    <div className={styles.container}>
      <img
        src="/tminus-logo@2x.webp"
        width={160}
        height={27}
        alt="Minus Logo"
        className="logo"
      />
      {connected && (
        <div className={styles.btnContainer}>
          <button className={styles.addressbtn}>
            {formatAddress(walletAddress)}
          </button>
          <p className={styles.disconnectBtn} onClick={disConnectWalletHandler}>
            Disconnect
          </p>
        </div>
      )}
    </div>
  );
};
export default Navbar;
