import styles from "../styles/customButton.module.css";

type CustomButtonProps = {
  children: React.ReactNode;
  variant: "outlined" | "contained";
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
};

const CustomButton = ({
  children,
  variant,
  className,
  disabled,
  onClick,
}: CustomButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.customButtonContainer} 
      ${variant === "contained" ? styles.contained : styles.outlined} 
      ${disabled ? styles.disabled : ""} 
      ${className}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default CustomButton;
