import { useState, useContext, useEffect } from "react";
import useConnectWalletStore from "../Store/ConnectWallet";

import styles from "../styles/Lockdrop.module.css";

interface Props {
  icon: string;
  name: string;
  btnClickHandler?: () => {};
}
const ConnectWalletBar = ({ icon, name, btnClickHandler }: Props) => {
  return (
    <div className={styles.walletBarContainer}>
      <div className={styles.walletDetails}>
        <img
          src={icon}
          width={36}
          height={36}
          alt="Wallet Logo"
          className="logo"
        />
        <p className={styles.walletName}>{name}</p>
      </div>

      <button className={styles.button} onClick={btnClickHandler}>
        Connect
      </button>
    </div>
  );
};
export default ConnectWalletBar;
