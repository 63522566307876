import {
  lovelace,
  Party,
  PolicyId,
  TokenName,
  datetoTimeout,
  Timeout,
  Constant,
  Environment,
  TimeInterval,
  mkEnvironment,
} from "@marlowe.io/language-core-v1";
import {
  BrowserRuntimeLifecycleOptions,
  mkRuntimeLifecycle,
} from "@marlowe.io/runtime-lifecycle/browser";
import { mkRestClient } from "@marlowe.io/runtime-rest-client";
import {
  SupportedWalletName,
  getInstalledWalletExtensions,
} from "@marlowe.io/wallet/browser";
import * as wallet from "@marlowe.io/wallet";
import React, { useEffect, useState } from "react";
import styles from "../styles/Lockdrop.module.css";
import mkLockdropDemo from "./LockdropDemoContract";
import ConnectWalletBar from "../Components/ConnectWalletBar";
import Navbar from "../Components/Navbar";
import EarningCard from "../Components/EarningCard";
import LockdropResultModal from "../Components/LockdropResultModal";
import CustomButton from "../Components/CustomButton";
import axios from "axios";
import {  z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { _BlockFrostAPI } from "../utils/blockfrostConfig";
import FadeLoader from "react-spinners/FadeLoader";
import useConnectWalletStore from "../Store/ConnectWallet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Countdown from "react-countdown";
// require("dotenv").config();

export type CardDataType = {
  contract_id: string;
  transaction_id: string;
  policy_id: string;
  asset_name: string;
  wallet_address: string;
  quantity: number;
  expiry_at: string;
  status: number;
  created_at: string;
  contract_status: string;
  deposit_expiry_at: string;
};

type ConnectedProps = {
  runtimeURL: string;
  onDisconnect: () => void;
  // selectedWalletExtensionName: string;
  onConnect: () => void;
  // setAndShowToast: (title: string, message: any, isDanger: boolean) => void;
};

export const ConnectedWallet: React.FC<ConnectedProps> = ({
  runtimeURL,
  // selectedWalletExtensionName,
  // setAndShowToast,
  onDisconnect,
  onConnect,
}) => {
  const [tokenInputValue, setTokenInputValue] = useState<number>(0);

  const [changeAddress, setChangeAddress] = useState<string>("");
  const [isMainnet, setIsMainnet] = useState<boolean>(false);
  const [lovelaceBalance, setLovelaceBalance] = useState<number>(0);
  const [_selectedWalletExtensionName, setSelectedWalletExtensionName] =
    useState<string>("");
  // const selectedWalletExtension = getInstalledWalletExtensions().filter(
  //   (extension) => extension.name === selectedWalletExtensionName
  // )[0];
  const [_tokenPolicy, setTokenPolicy] = useState<string>(
    "055bff0c5afc7f91f12a76fbfaa1126de7f9cea5652e7dca8bb3b0f6"
  );
  // const [_tokenAsset, setTokenAsset] = useState<string>("PreviewTestToken");
  // const [_timeoutUnlock, setTimeoutUnlock] = useState<string>(
  //   "2024-04-25 23:20:59"
  // );
  const [_depositAmount, setDepositAmount] = useState<number>(0);
  const [_walletAddr, setWalletAddr] = useState<string>("");
  // const [_contractID, setContractID] = useState<string>("");
  const [_lockdropList, setLockdropList] = useState([]);
  const [_runtimeLifeCycle, setRuntimeLifecycle] = useState();
  // const [_myWallet, setMyWallet] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [successContractClose, setSuccessContractClose] =
    useState<boolean>(false);
  const [maxAmount, setMaxAmount] = useState<number>(0);

  const [openDisplayModal, setOpenDisplayModal] = useState({
    open: false,
    type: "fail",
    msg: "Operation failed.",
  });
  const installedWalletExtensions = getInstalledWalletExtensions();
  const validationSchema = z.object({
    amount: z
      .number()
      .min(10000, { message: "Amount must be at least 10000 BPTS" })
      .nullable()
      .refine((val) => val && val <= maxAmount, {
        message: `Amount must be less than or equal to ${maxAmount} BPTS`,
      }),
  });
  type validationSchemaType = z.infer<typeof validationSchema>;

  const setWalletAddress = useConnectWalletStore(
    (state) => state.setWalletAddress
  );
  const walletAddress = useConnectWalletStore((state) => state.walletAddress);
  const setConnected = useConnectWalletStore((state) => state.setConnected);
  const connected = useConnectWalletStore((state) => state.connected);
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<validationSchemaType>({
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = (data: validationSchemaType) => {
    // console.log("Valid amount:", data?.amount);
    // console.log("typeof data?.amount", typeof data?.amount);
    if (typeof data?.amount == "number") {
      // console.log("passed type checking");
      handleSimpleDemo(data?.amount);
      setTokenInputValue(data?.amount);
      setDepositAmount(data?.amount);
    }
  };
   const maximumCalculationAPI = async () => {
    const myWallet = await wallet.mkBrowserWallet(
      _selectedWalletExtensionName as SupportedWalletName
    );

    const results = await myWallet.getTokens();
    //console.log("test", results);
    const targetPolicyId =
      "055bff0c5afc7f91f12a76fbfaa1126de7f9cea5652e7dca8bb3b0f6";

    const targetAsset = results.find(
      (item) => item.assetId && item.assetId.policyId === targetPolicyId
    );

    const quantity = targetAsset ? targetAsset.quantity : null;
    //console.log("fetch maxAmount", quantity);
    setTokenInputValue(Number(quantity));
    setMaxAmount(Number(quantity));
  };
  const fetchMaximumValue = async () => {
    await maximumCalculationAPI();
    setValue("amount", Number(maxAmount), {
      shouldValidate: true,
    });
  };
  const fetchInitialValue = async () => {
    await maximumCalculationAPI();
    setValue("amount", 0, {
      shouldValidate: true,
    });
  };
  // };
  const currentUTCTime = new Date()
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  const depositTimeoutISO8601 = new Date(process.env.REACT_APP_DEPOSIT_TIME_OUT)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

  let isDepositDisabled =
    new Date(currentUTCTime) > new Date(depositTimeoutISO8601);
  const calculateRuntimelifeCycle = async () => {
    try {
      // 這裡連結 Marlowe Runtime
      const myWallet = await wallet.mkBrowserWallet(
        _selectedWalletExtensionName as SupportedWalletName
      );
      // console.log("myWallet", myWallet);
      //@ts-ignore
      const runtimeLifecycleParameters: BrowserRuntimeLifecycleOptions = {
        runtimeURL: runtimeURL,
        // walletName: "nami",
        walletName: _selectedWalletExtensionName as SupportedWalletName,
      };
      // console.log("_selectedWalletExtensionName", _selectedWalletExtensionName);
      // console.log("runtimeLifecycleParameters", runtimeLifecycleParameters);
      const runtimeLifecycle = await mkRuntimeLifecycle(
        runtimeLifecycleParameters
      );
      // console.log("runtimeLifecycle", runtimeLifecycle);
      return runtimeLifecycle;
    } catch (err: any) {
      // console.log("Error", err);
      setOpenDisplayModal({
        open: true,
        type: "fail",
        msg: "Mawlowe Runtime failed to initiate.",
      });
    }
  };

  const namiClickHandler = async () => {
    setConnected(true);
    const isNamiInstalled = installedWalletExtensions.some(
      (extension) => extension.name === "Nami"
    );
    // console.log("isNamiInstalled", isNamiInstalled);
    localStorage.setItem("walletProvider", "nami");
    // console.log(
    //   "Currently connected wallet: ",
    //   localStorage.getItem("walletProvider")
    // );
    // setWalletAddr(changeAddress);
    let selectedWalletExtensionName = "nami";
    setSelectedWalletExtensionName(selectedWalletExtensionName);

    try {
      setLoading(true);
      // 這裡連結 Marlowe Runtime
      const myWallet = await wallet.mkBrowserWallet(
        // _selectedWalletExtensionName as SupportedWalletName
        "nami"
      );
      // console.log("myWallet", myWallet);
      //@ts-ignore
      // setMyWallet(myWallet);
      const runtimeLifecycleParameters: BrowserRuntimeLifecycleOptions = {
        runtimeURL: runtimeURL,
        walletName: selectedWalletExtensionName as SupportedWalletName,
      };
      // console.log("runtimeLifecycleParameters", runtimeLifecycleParameters);
      const runtimeLifecycle = await mkRuntimeLifecycle(
        runtimeLifecycleParameters
      );
      // console.log("runtimeLifecycle", runtimeLifecycle);
      //@ts-ignore
      setRuntimeLifecycle(runtimeLifecycle);
      const _changeAddress = await runtimeLifecycle.wallet
        .getChangeAddress()
        .then((changeAddress) => {
          setChangeAddress(changeAddress);
          setWalletAddress(changeAddress);
          return changeAddress;
        });
      // console.log("_changeAddress", _changeAddress);

      setWalletAddr(_changeAddress);
      const _runtimeLifecycle = await runtimeLifecycle.wallet
        .isMainnet()
        .then(setIsMainnet);
      // console.log("_runtimeLifecycle", _runtimeLifecycle);
      const result = await runtimeLifecycle.wallet
        .getLovelaces()
        .then((a) => setLovelaceBalance(Number(a)));
      // console.log("result", result);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      // console.log("Error", err);
      setOpenDisplayModal({
        open: true,
        type: "fail",
        msg: typeof err !== "string" ? "Operation failure" : err,
      });
    }
  };
  const eternlClickHandler = async () => {
    setConnected(true);
    const isEternlInstalled = installedWalletExtensions.some(
      (extension) => extension.name === "eternl"
    );
    // console.log("isEternlInstalled", isEternlInstalled);
    localStorage.setItem("walletProvider", "eternl");
    // console.log(
    //   "Currently connected wallet: ",
    //   localStorage.getItem("walletProvider")
    // );
    let selectedWalletExtensionName = "eternl";
    // console.log("selectedWalletExtensionName", selectedWalletExtensionName);
    setSelectedWalletExtensionName(selectedWalletExtensionName);
    try {
      setLoading(true);
      // 這裡連結 Marlowe Runtime
      const myWallet = await wallet.mkBrowserWallet(
        // _selectedWalletExtensionName as SupportedWalletName
        "eternl"
      );
      // console.log("myWallet", myWallet);
      //@ts-ignore
      // setMyWallet(myWallet);
      const runtimeLifecycleParameters: BrowserRuntimeLifecycleOptions = {
        runtimeURL: runtimeURL,
        walletName: selectedWalletExtensionName as SupportedWalletName,
      };
      // console.log("runtimeLifecycleParameters", runtimeLifecycleParameters);
      const runtimeLifecycle = await mkRuntimeLifecycle(
        runtimeLifecycleParameters
      );
      // console.log("runtimeLifecycle", runtimeLifecycle);
      //@ts-ignore
      setRuntimeLifecycle(runtimeLifecycle);
      const _changeAddress = await runtimeLifecycle.wallet
        .getChangeAddress()
        .then((changeAddress) => {
          setWalletAddress(changeAddress);
          setChangeAddress(changeAddress);
          return changeAddress;
        });
      // console.log("_changeAddress", _changeAddress);
      setWalletAddr(_changeAddress);
      const _runtimeLifecycle = await runtimeLifecycle.wallet
        .isMainnet()
        .then(setIsMainnet);
      // console.log("_runtimeLifecycle", _runtimeLifecycle);
      const result = await runtimeLifecycle.wallet
        .getLovelaces()
        .then((a) => setLovelaceBalance(Number(a)));
      // console.log("result", result);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      // console.log("Error", err);
      setOpenDisplayModal({
        open: true,
        type: "fail",
        msg: "Operation failed.",
      });
    }
  };

  const createLockdropContractAPI = async (
    _contractId: string,
    _tokenPolicy: string,
    _tokenAssetname: string,
    _walletAddr: string,
    _unlockTimeout: string,
    _depositAmount: number,
    _depositTimeout: string
  ) => {
    let lockdropFormData = new FormData();
    lockdropFormData.append("contract_id", _contractId.toString());
    lockdropFormData.append(
      "transaction_id",
      _contractId.slice(0, -2).toString()
    );
    lockdropFormData.append("policy_id", _tokenPolicy.toString());
    lockdropFormData.append("asset_name", _tokenAssetname.toString());
    lockdropFormData.append("wallet_address", _walletAddr.toString());
    lockdropFormData.append("expiry_at", _unlockTimeout.toString());
    lockdropFormData.append("deposit_expiry_at", _depositTimeout.toString());
    lockdropFormData.append("quantity", _depositAmount.toString());

    // for (let [key, value] of lockdropFormData.entries()) {
    //   console.log("lockdropFormData", key, value);
    // }

    const results = await axios(
      // @ts-ignore
      {
        method: "post",
        url: " https://api.t-minus.io/lockdrop/create_contract.php",
        data: lockdropFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    // console.log("results", results.data.code);
    return results?.data.code;
  };

  const depositContractAPI = async (
    _contractId: string,
    transaction_id: string,
    depositAmount: number,
    deposit_expiry_at: string
  ) => {
    let lockdropFormData = new FormData();
    lockdropFormData.append("contract_id", _contractId.toString());
    lockdropFormData.append("quantity", depositAmount.toString());
    lockdropFormData.append("transaction_id", transaction_id.toString());
    lockdropFormData.append("deposit_expiry_at", deposit_expiry_at.toString());

    // const data = {
    //   contract_id: _contractId,
    //   policy_id: _tokenPolicy,
    //   asset_name: _tokenAssetname,
    //   wallet_address: _walletAddr,
    //   expiry_at: _unlockTimeout,
    // };
    // console.log("post data", data);
    // console.log("lockdropFormData", lockdropFormData);
    const results = await axios(
      // @ts-ignore
      {
        method: "post",
        url: " https://api.t-minus.io/lockdrop/lockdrop_deposit.php",
        data: lockdropFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    // console.log("results", results.data.code);
    return results?.data.code;
  };

  const getLockdropListAPI = async () =>
    // _contractId: string,
    // wallet_address: string
    {
      try {
        let getlockdropFormData = new FormData();

        // getlockdropFormData.append("contract_id", _contractId.toString());
        // getlockdropFormData.append("lock", "all");
        getlockdropFormData.append("wallet_address", _walletAddr.toString());

        // console.log("lockdropFormData", getlockdropFormData);
        const results = await axios(
          // @ts-ignore
          {
            method: "post",
            url: " https://api.t-minus.io/lockdrop/get_lockdrop_list_by_wallet_address.php",
            data: getlockdropFormData,
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        //console.log("results msg", results.data);
        //console.log("results?.data.code ", results?.data.code);
        if (results?.data.code.toString() === "0")
          setLockdropList(results?.data.msg);
      } catch (err) {
        // @ts-ignore
        // setLockdropList(Carddata);
        //console.log("err", err);
      }
    };

  const updateContractStatusAPI = async (
    _contractId: string,
    _status: string
  ) =>
    // _contractId: string,
    // wallet_address: string
    {
      try {
        let updatelockdropFormData = new FormData();

        // getlockdropFormData.append("contract_id", _contractId.toString());
        // getlockdropFormData.append("lock", "all");
        updatelockdropFormData.append("contract_id", _contractId.toString());
        updatelockdropFormData.append("status", _status);

        // console.log("lockdropFormData", updatelockdropFormData);
        const results = await axios(
          // @ts-ignore
          {
            method: "post",
            url: " https://api.t-minus.io/lockdrop/update_contract_status.php",
            data: updatelockdropFormData,
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        // console.log("results msg", results.data);
      } catch (err) {
        // @ts-ignore
        // setLockdropList(Carddata);
        //console.log("err", err);
      }
    };

   useEffect(() => {
    if (connected) {
      fetchInitialValue();
      getLockdropListAPI();
    }
  }, [_walletAddr]);
  
  const adas = Math.trunc(lovelaceBalance / 1_000_000);
  const decimalADAs = lovelaceBalance % 1_000_000;
  const msTillDepositEnd =
    new Date(process.env.REACT_APP_DEPOSIT_TIME_OUT).getTime() - Date.now();

  const handleSimpleDemo = async (depositAmount: number) => {
    try {
      setLoading(true);
      // Convert amount to BigInt
      if (!depositAmount) throw new Error("deposit amount is undefined");
      const bintAmount: Constant = BigInt(depositAmount);

      // console.log("Handling Simple Lockdrop with Token Amount:", bintAmount);

      // Connect to Marlowe Runtime
      const myWallet = await wallet.mkBrowserWallet(
        _selectedWalletExtensionName as SupportedWalletName
      );

      const runtimeLifecycleParameters: BrowserRuntimeLifecycleOptions = {
        runtimeURL: runtimeURL,
        walletName: _selectedWalletExtensionName as SupportedWalletName,
      };
      // console.log("RuntimeURL Setting:", runtimeURL);

      const runtimeLifecycle = await mkRuntimeLifecycle(
        runtimeLifecycleParameters
      );
      const walletAddr = await myWallet.getChangeAddress();
      const user: Party = { address: walletAddr };

      // Token Policy and Assetname
      const tokenPolicy: Policy = "055bff0c5afc7f91f12a76fbfaa1126de7f9cea5652e7dca8bb3b0f6";
      const tokenAssetname: TokenName = "BPTS"; 
      // console.log(`Token Info: ${tokenPolicy}.${tokenAssetname}`);

      let depositTimeout; // Declare depositTimeout at a higher scope
      let unlockTimeout; // Declare unlockTimeout at a higher scope

      if (process.env.REACT_APP_DEPOSIT_TIME_OUT === undefined) {
        throw new Error("Deposit Time Out is undefined");
      } else {
        // console.log(
        //   "REACT_APP_DEPOSIT_TIME_OUT",
        //   process.env.REACT_APP_DEPOSIT_TIME_OUT
        // );
        depositTimeout = datetoTimeout(
          new Date(process.env.REACT_APP_DEPOSIT_TIME_OUT)
        ); // Removed the 'let' keyword
        // console.log("updated depositTimeout", depositTimeout);
      }

      if (process.env.REACT_APP_UNLOCK_TIME_OUT === undefined) {
        throw new Error("Unlock Time Out is undefined");
      } else {
        // console.log(
        //   "REACT_APP_UNLOCK_TIME_OUT",
        //   process.env.REACT_APP_UNLOCK_TIME_OUT
        // );

        unlockTimeout = datetoTimeout(
          new Date(process.env.REACT_APP_UNLOCK_TIME_OUT)
        ); // Removed the 'let' keyword

        // console.log("Updated unlockTimeout", unlockTimeout);
      }

      // Create contract
      //console.log("final depositTimeout", depositTimeout);
      //console.log("final unlockTimeout", unlockTimeout);
      //console.log("depositAmount", depositAmount);
      //console.log("bintAmount", bintAmount);   
      let potentialEarnings = Math.floor((depositAmount || 0) * 0.01);
      //console.log("potentialEarnings",potentialEarnings);   

      const unlockTimeoutISO8601 = new Date(
        process.env.REACT_APP_UNLOCK_TIME_OUT
      )
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      const depositTimeoutISO8601 = new Date(
        process.env.REACT_APP_DEPOSIT_TIME_OUT
      )
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      // console.log("unlockTimeoutISO8601", unlockTimeoutISO8601);
      // console.log("depositTimeoutISO8601", depositTimeoutISO8601);
      // console.log("walletAddr", walletAddr);

      const myContract = mkLockdropDemo(
        bintAmount,
        user,
        tokenPolicy,
        tokenAssetname,
        unlockTimeout,
        depositTimeout
      );

      const contractInstance = await runtimeLifecycle.newContractAPI.create({
        contract: myContract,
        tags: {
            "t-minus-bpts-lockdrop": [
              bintAmount,
              depositTimeout,
              unlockTimeout,
              potentialEarnings,
            ],
          },        
      });         
      const contractId = contractInstance.id;

      //這裡可以用下列方式來抓出有 t-minus-test-lockdrop 這個 tag 的合約
      //curl -L 'https://dmtr_marlowe_v1_preprod_10pcnxu3jda39gkrwg5p47a9u.marlowe-m1.demeter.run/contracts?tag=t-minus-test-lockdrop' -H 'Accept: application/json;charset=utf-8'
      // console.log("Contract ID:", contractId);
      // console.log("Tx ID", contractId.slice(0, -2));

      //const contractConfirm = await myWallet.waitConfirmation(txnId);
      // console.log("Contract Creation Confirmed:", contractConfirm);
      const contractConfirm = await contractInstance.waitForConfirmation();
      //console.log("contractConfirm",contractConfirm);

      const results = await createLockdropContractAPI(
        contractId,
        tokenPolicy,
        tokenAssetname,
        walletAddr,
        unlockTimeoutISO8601,
        depositAmount,
        depositTimeoutISO8601
      );
      // console.log("results", results);
      if (results?.toString() === "0") {
        const updateStatus = await updateContractStatusAPI(contractId, "2");
        // console.log("updateStatus", updateStatus);
        setOpenDisplayModal({
          open: true,
          type: "success",
          msg: "Lockdrop contract created successfully.",
        });
        await getLockdropListAPI();
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      // console.log("Error:", err);
      // @ts-ignore
      setOpenDisplayModal({
        open: true,
        type: "fail",
        msg: "Lockdrop contract creation failed.",
      });
    }
  };

  const handleResync = async (
    contract_id: string
  ) => {

    try {
      setLoading(true);
      const myWallet = await wallet.mkBrowserWallet(
        _selectedWalletExtensionName as SupportedWalletName
      );

      const runtimeLifecycleParameters: BrowserRuntimeLifecycleOptions = {
        runtimeURL: runtimeURL,
        walletName: _selectedWalletExtensionName as SupportedWalletName,
      };

      const runtimeLifecycle = await mkRuntimeLifecycle(
        runtimeLifecycleParameters
      );
      
      const client = mkRestClient(runtimeURL);
      const contractDetails = await client.getContractById({contractId: contract_id});
      const isTokenAssetNull =
        Object.keys(contractDetails.assets.tokens).length === 0;  

      const contractInstance = await runtimeLifecycle.newContractAPI.load(contract_id);
      const applicableActions = await contractInstance.evaluateApplicableActions();
      let isClosed;

      const choices: Array<{
          name: string;
          value: CanDeposit | CanAdvance | { type: "check-state" } | { type: "return" };
        }> = [
          ...applicableActions.myActions.map((action) => {
            switch (action.type) {
              case "Advance":
                return {
                  name: "Close",
                  value: action,
                };                
              case "Deposit":
                return {
                  name: `Deposit`,
                  value: action,
                };
              default:
                throw new Error("Unexpected action type");
            }
          }),
        ];       

        //console.log(choices)
        isClosed = await contractInstance.isClosed();
        //console.log("isClosed",isClosed)  
        //console.log("isTokenAssetNull",isTokenAssetNull)      

        if(choices.length){
          if(choices[0].name == "Deposit"){
              //If backend has a mistake and mark the contract already deposted. Can fix here.
              //console.log("Can be deposited. check with backend status");
          }
          if(choices[0].name == "Close" && isTokenAssetNull){
              //contract can be closed as expired
              //console.log("contract can be closed. no asset deposited. check with backend status");
          }
          if(choices[0].name == "Close" && !isTokenAssetNull){
              //contract can vbe closed as withdrawing asset
              //console.log("contract can be closed and assets be withdrawn. check with backend status");
          }          
        }else if(!isTokenAssetNull){
          //deposited. Can fix backend here.
          //console.log("contract deposited wait for maturity");
        }else{
          //if contract already closed. Can fix backend here.
          //console.log("contract already closed");          
        }        

      //await getLockdropListAPI();
      setLoading(false);
    } catch (err: any) {
      //console.log("Error:");
      // @ts-ignore
      setLoading(false);
    }
  };

  //發起下一個交易，存入Token
  // Deposit func
  const handleDeposit = async (
    quantity: string,
    asset_name: string,
    policy_id: string,
    contract_id: string
  ) => {

    const user: Party = { address: _walletAddr };
    const bintAmount: Constant = BigInt(+quantity);
    const tokenAssetname: TokenName = asset_name.trim();
    const tokenPolicy: Policy = policy_id.trim(); // Ensure policy_id is used from parameter

    try {
      setLoading(true);
      const myWallet = await wallet.mkBrowserWallet(
        _selectedWalletExtensionName as SupportedWalletName
      );

      const runtimeLifecycleParameters: BrowserRuntimeLifecycleOptions = {
        runtimeURL: runtimeURL,
        walletName: _selectedWalletExtensionName as SupportedWalletName,
      };

      const runtimeLifecycle = await mkRuntimeLifecycle(
        runtimeLifecycleParameters
      );

      const contractInstance = await runtimeLifecycle.newContractAPI.load(contract_id);
      const applicableActions = await contractInstance.evaluateApplicableActions();

      let txId;   
      let isTokenAssetNull;   
   
      const choices: Array<{
          name: string;
          value: CanDeposit | CanAdvance | { type: "check-state" } | { type: "return" };
        }> = [
          ...applicableActions.myActions.map((action) => {
            switch (action.type) {
              case "Advance":
                return {
                  name: "Close",
                  value: action,
                };                
              case "Deposit":
                return {
                  name: `Deposit`,
                  value: action,
                };
              default:
                throw new Error("Unexpected action type");
            }
          }),
        ];       

        if(choices.length){
          //如果合約可以存入 Token，就跑 Deposit 流程  
          if(choices[0].name == "Deposit"){

            const withTimeout = (promise, timeout) => {
              return Promise.race([
                  promise,
                  new Promise((_, reject) =>
                  setTimeout(() => reject(new Error('Request timed out')), timeout)
                  ),
              ]);
            };  

            const applicableInput = await applicableActions.toInput(choices[0].value);
            //console.log(applicableInput)
            try{
                //console.log("perform deposit")
                txId = await withTimeout(applicableActions.apply({input: applicableInput,}),120000);
                //console.log("txId", txId);                   
                const txConfirm = await withTimeout(myWallet.waitConfirmation(txId),300000);
                //console.log("txConfirm",txConfirm)                
            }catch (error) {
                // Handle error here
                console.error("An error occurred, double-checking in progress:", error);
                const client = mkRestClient(runtimeURL);
                const contractDetails = await client.getContractById({contractId: contract_id});
                isTokenAssetNull = Object.keys(contractDetails.assets.tokens).length === 0;                  
                //console.log("isTokenAssetNull",isTokenAssetNull)
            }
          }
        }        

      if (!txId || isTokenAssetNull) throw new Error("deposit transaction failed");  

      const depositTimeoutISO8601 = new Date(
        process.env.REACT_APP_DEPOSIT_TIME_OUT
      )
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      await depositContractAPI(
        contract_id,
        // @ts-ignore
        txId,
        +quantity,
        depositTimeoutISO8601
      );

      const updateStatus = await updateContractStatusAPI(contract_id, "4");

      setOpenDisplayModal({
        open: true,
        type: "success",
        msg: "Tokens deposited successfully.",
      });
      await getLockdropListAPI();
      setLoading(false);
    } catch (err: any) {
      // console.log("Error:", err);
      setOpenDisplayModal({
        open: true,
        type: "fail",
        msg: "Deposit operation failed.",
      });
      // @ts-ignore
      setLoading(false);
    }
  };

  const handleClose = async (contract_id: string) => {

    try {
      setLoading(true);
      const myWallet = await wallet.mkBrowserWallet(
        _selectedWalletExtensionName as SupportedWalletName
      );


      const runtimeLifecycleParameters: BrowserRuntimeLifecycleOptions = {
        runtimeURL: runtimeURL,
        walletName: _selectedWalletExtensionName as SupportedWalletName,
      };
      // console.log("RuntimeURL Setting:", runtimeURL);

      const runtimeLifecycle = await mkRuntimeLifecycle(
        runtimeLifecycleParameters
      );

      const contractInstance = await runtimeLifecycle.newContractAPI.load(contract_id);
      const applicableActions = await contractInstance.evaluateApplicableActions();
      const client = mkRestClient(runtimeURL);
      const contractDetails = await client.getContractById({contractId: contract_id});
      //console.log("ContractDetails 結果:", contractDetails.assets.tokens);
      const isTokenAssetNull =
        Object.keys(contractDetails.assets.tokens).length === 0;
      // console.log("isTokenAssetNull:", isTokenAssetNull);

      //const inputs: Input[] = [];
      //const depositRequest: ApplyInputsRequest = { inputs };

      //const runTimeLifeCycleResults = await calculateRuntimelifeCycle();

      let txId;   
      let isClosed;
      //console.log("txId", txId);      
      //console.log("isClosed",isClosed)
      const choices: Array<{
          name: string;
          value: CanDeposit | CanAdvance | { type: "check-state" } | { type: "return" };
        }> = [
          ...applicableActions.myActions.map((action) => {
            switch (action.type) {
              case "Advance":
                return {
                  name: "Close",
                  value: action,
                };                
              case "Deposit":
                return {
                  name: `Deposit`,
                  value: action,
                };
              default:
                throw new Error("Unexpected action type");
            }
          }),
        ];  

        if(choices.length){ 
          //如果合約可以被 close，就跑這個流程
          if(choices[0].name == "Close"){

              const withTimeout = (promise, timeout) => {
                return Promise.race([
                    promise,
                    new Promise((_, reject) =>
                    setTimeout(() => reject(new Error('Request timed out')), timeout)
                    ),
                ]);
              };            

              const inputs: Input[] = [];

              const applicableInput: ApplicableInput = {
                inputs: inputs,
                environment: choices[0].value.environment
              }

              const applyApplicableInputRequest: ApplyApplicableInputRequest = {
                input: applicableInput,
              }

              try{
                //console.log("perform closing")
                txId = await withTimeout(contractInstance.applyInput(applyApplicableInputRequest),120000);   
                //console.log("txId", txId);                    
                const txConfirm = await withTimeout(myWallet.waitConfirmation(txId),300000);
                //console.log("txConfirm",txConfirm)                                 
              }catch (error) {
                // Handle error here
                console.error("An error occurred, double-checking in progress:", error);
                isClosed = await contractInstance.isClosed();
                //console.log("isClosed",isClosed)
              }
 
          }
        }  

      //console.log("txId", txId);
      if (!txId && !isClosed) throw new Error("contract not closed");
      //const depositConfirm = await myWallet?.waitConfirmation(txId);
      // console.log("depositConfirm", depositConfirm);
      if (isTokenAssetNull) {
        const closeStatus = await updateContractStatusAPI(contract_id, "8");
        // console.log("update contract Status", closeStatus);
        setOpenDisplayModal({
          open: true,
          type: "success",
          msg: "Lockdrop closed. No rewards earned due to no deposit.",
        });
      } else {
        const closeStatus = await updateContractStatusAPI(contract_id, "9");
        // console.log("update contract Status", closeStatus);
        setOpenDisplayModal({
          open: true,
          type: "success",
          msg: "Lockdrop completed. Rewards will be claimable soon.",
        });
      }

      setSuccessContractClose(true);
      await getLockdropListAPI();
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      // console.log("test Error:", err);
      // @ts-ignore
      setOpenDisplayModal({
        open: true,
        type: "fail",
        msg: "Lockdrop contract failed to close.",
      });
    }
  };

  return (
    <>
      <div className={styles.lockdropContainer}>
        <Navbar />
        <div className={styles.contentContainer}>
          <div className={styles.leftColumn}>
            <h1>LOCKDROP</h1>
            <p className={styles.leftIntro}>
              Start earning T-Minus Points (TXP) by locking your Boss Points
              into a Lockdrop smart contract. This process is fully
              non-custodial. You will create and initiate your own Lockdrop
              contract built on top of Marlowe, a new framework for interacting
              with Cardano smart contract. The following rules apply to
              Lockdrop: Minimum amount of 10,000 BPTS required. Deposited tokens
              will only be unlocked on maturity. Unlocked asset will be
              automatically sent back to your wallet. Contract creation and
              token deposit will be two seperate transactions. TXP can be
              claimed into native tokens in the future.
            </p>
            <p className={styles.lockableToken}>Lockable Token:</p>
            <div className={styles.bossPointsContent}>
              <p className={styles.bpts}>$BPTS</p>
              <p className={styles.bossPoints}>(Boss Points)</p>
            </div>
            <p></p>
            <p className={styles.lockableToken}>Deposit Deadline:</p>
            <Countdown
              className={styles.bpts}
              date={Date.now() + msTillDepositEnd}
            />
          </div>
          <div className={styles.rightColumn}>
            {!connected ? (
              <div>
                <p className={styles.connectWallet}>Connect Wallet</p>
                <div className={styles.inputWrapper}>
                  <ConnectWalletBar
                    icon="/nami@2x.webp"
                    name="Nami"
                    btnClickHandler={namiClickHandler}
                  />
                  <ConnectWalletBar
                    icon="/eternl@2x.webp"
                    name="Eternl"
                    btnClickHandler={eternlClickHandler}
                  />
                </div>
                {/* <ConnectWalletBar
            icon="/eternl@2x.webp"
            name="Eternl"
            btnClickHandler={handleSimpleDemo}
          />
 */}
                {/* <button onClick={handleSimpleDemo}>handleSimpleDemo</button> */}
              </div>
            ) : (
              <>
                <p className={styles.lockTokenTitle}>Lock Token</p>
                <div className={styles.lockTokenContainer}>
                  <p className={styles.lockTokenText}>
                    Available lockable token in wallet:
                  </p>
                  <p className={styles.lockTokenEarning}>
                    {maxAmount?.toLocaleString()} BPTS
                  </p>
                  <p className={styles.lockTokenText}> Enter amount to lock:</p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.tokenInputContainer}>
                      <input
                        className={styles.tokenInput}
                        {...register("amount", { valueAsNumber: true })}
                        onChange={(e) => {
                          setTokenInputValue(Number(e.currentTarget.value));
                          setValue("amount", Number(e.currentTarget.value), {
                            shouldValidate: true,
                          });
                          trigger("amount");
                        }}
                      />

                      <button
                        className={styles.maxButton}
                        onClick={fetchMaximumValue}
                        type="button"
                      >
                        Max
                      </button>
                    </div>
                    {errors.amount ? (
                      <p className={styles.tokenInputErrorMessage}>
                        {errors?.amount.message}
                      </p>
                    ) : (
                      <p
                        className={`${styles.lockTokenText} ${styles.potentialEarning}`}
                      >
                        Potential earnings:{" "}
                        {Math.floor(tokenInputValue * 0.01)?.toLocaleString()}{" "}
                        TXP
                      </p>
                    )}

                    <CustomButton
                      variant="contained"
                      disabled={isDepositDisabled}
                    >
                      {isDepositDisabled
                        ? "Deposit Period Ended "
                        : "Create Lockdrop"}
                    </CustomButton>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
        {connected && (
          <div className={styles.myLockdropdownContainer}>
            <div className={styles.lockdropContent}>
              <h4 className={styles.myLockdropTitle}>My Lockdrops</h4>
              <button
                className={styles.refreshButton}
                onClick={getLockdropListAPI}
              >
                <img
                  src="/images/refresh-icon.svg"
                  alt="refresh"
                  width={15}
                  height={15}
                  className={styles.refreshIcon}
                />
                Refresh
              </button>
            </div>
            <p className={styles.myLockdropDesc}>
              There is a deadline for deposit after each Lockdrop contract is
              created. Please make sure there are sufficient tokens in the
              wallet for a successful transaction. When the deposit is compete,
              you will see the amount of TXP credited. Currently For each 100
              BPTS deposited, 1 TXP will be earned.
            </p>
            <div>
              {/* {CardData.map((data, index) => (
              <EarningCard
                key={index}
                status={data.status}
                earning={data.earning}
                potentialEarningTitle={data.potentialEarningTitle}
                potentialEarning={data.potentialEarning}
                durationTitle={data.durationTitle}
                duration={data.duration}
                buttonText={data.buttonText}
                className={styles.earningCardStyle}
                handleDeposit={handleDeposit}
              />
            ))} */}
              {/* <Slider {...settings} className=""> */}
              <div className={styles.earningCardWrapper}>
                {_lockdropList?.map((data: CardDataType, index) => (
                  <EarningCard
                    key={index}
                    data={data}
                    contractStatus={data.contract_status}
                    quantity={data.quantity.toString()}
                    // potentialEarningTitle={data.potentialEarningTitle}
                    // potentialEarning={data.potentialEarning}
                    durationTitle="Ends in"
                    duration={data?.expiry_at}
                    contractCreatedTime={data?.created_at}
                    contractDepositDeadline={data?.deposit_expiry_at}
                    earningValue={"900"} //TODO: add earning value from map
                    // buttonText={data.buttonText}
                    className={styles.earningCardStyle}
                    handleDeposit={handleDeposit}
                    handleClose={handleClose}
                    handleResync={handleResync}
                  />
                ))}
              </div>

              {loading && (
                <div className={styles.spinnerPageCover}>
                  <div className={styles.spinnerWrapper}>
                    <FadeLoader color="grey" />
                    <p className={styles.moment}>
                      Loading, please do not close this browser window.
                    </p>
                  </div>
                </div>
              )}
              <LockdropResultModal
                open={openDisplayModal.open}
                type={openDisplayModal.type}
                msg={openDisplayModal.msg}
                closeDialog={() =>
                  setOpenDisplayModal({
                    open: false,
                    type: "",
                    msg: "test",
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};
