import styles from "../styles/earningCard.module.css";
import CustomButton from "./CustomButton";
import { CardDataType } from "../Connection/index";
import moment from 'moment';
import Countdown from 'react-countdown';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from "react-tooltip";

// import {depositSteps} from '../types/store.tsx';
type EarningCardProps = {
  contractStatus: string;
  quantity: string;
  // potentialEarningTitle: string;
  // potentialEarning: string;
  durationTitle?: string;
  duration: string;
  contractCreatedTime: string;
  // buttonText: string;
  contractDepositDeadline: string;
  className?: string;
  data?: CardDataType;
  handleDeposit: (
    quantity: string,
    asset_name: string,
    policy_id: string,
    contract_id: string
  ) => {};
  handleClose: (contract_id: string) => {};
  handleResync: (contract_id: string) => {};
  earningValue?: string;
};

const EarningCard = ({
  contractStatus,
  quantity,
  // potentialEarningTitle,
  // potentialEarning,
  durationTitle,
  duration,
  contractCreatedTime,
  contractDepositDeadline,
  // buttonText,
  className,
  data,
  handleDeposit,
  handleClose,
  handleResync,
  earningValue,
}: EarningCardProps) => {
  //const currentUTCTime = new Date().getTime();
  const currentUTCTime = new Date().toISOString().slice(0, 19).replace('T', ' ');
  //const hoursDifference = 8;
  //const millisecondsDifference = hoursDifference * 60 * 60 * 1000;

  const _contractStatus = data?.status?.toString();

  const depositbtnOpened = new Date(currentUTCTime) < new Date(contractDepositDeadline)

  const depositDeadlineTimestamp = new Date(moment.utc(contractDepositDeadline, 'YYYY-MM-DD HH:mm:ss')).getTime();
  const contractDeadlineTimestamp = new Date(moment.utc(duration, 'YYYY-MM-DD HH:mm:ss')).getTime();



  // Remove this logic - if exceed 30 mins after user create contract then show close btn

  // if created contract but didn't desposit in time then still must close.
  // if deposit in time then can close till close starting time, so will not show close btn if close time doesn't start.
  // if deposit in time then can close till close starting time, but didn't close and exceed contract duration time then still showing close.

  // duration means close time in UTC
  //const msTillEnd = new Date(duration).getTime() - Date.now();
  const msTillEnd = contractDeadlineTimestamp - Date.now();
  const isLockdropTimeout = Date.now() > contractDeadlineTimestamp


  const days = Math.trunc(msTillEnd / 86400000);
  

  let isBtnDisabled =
    data?.status?.toString() === "8" ||
    data?.status?.toString() === "9" ||
    // msTillEnd means close time, if exceed close time then show Withdraw btn. if haven't exceed close time then need to wait till close time.
    //(data?.status?.toString() === "4" && msTillEnd > 0)
    (data?.status?.toString() === "4" && !isLockdropTimeout)
      ? true
      : false;

  const btnTextHandler = (_contractStatus: string | undefined) => {
    switch (_contractStatus) {
      case "2":
        // depositbtnOpened means deposit time , if didn't deposit then need to close contract.
        return depositbtnOpened ? "Deposit" : "Close";
      case "4":
        // msTillEnd means close time, if exceed close time then show Withdraw btn. if haven't exceed close time then need to wait till close time.
        //return msTillEnd > 0 ? "Deposited" : "Withdraw";
        return !isLockdropTimeout ? "Deposited" : "Withdraw";
      case "8":
        return "Expired";
      case "9":
        return "Completed";
      default:
        return "";
    }
  };
  const onClickHandler = (_contractStatus: string | undefined) => {
    if (!data) return;

    const { quantity, asset_name, policy_id, contract_id } = data;
  

    if (_contractStatus === "2") {
      if (depositbtnOpened) {
        handleDeposit(
          data?.quantity.toString(),
          data?.asset_name,
          data?.policy_id,
          data?.contract_id
        );
       
      } else {
        handleClose(contract_id);
       
      }
    //} else if (_contractStatus === "4" && msTillEnd < 0) {
    } else if (_contractStatus === "4" && isLockdropTimeout) {  
      handleClose(contract_id);
    }
  };
  return (
    <div className={`${styles.earningCardContainer} ${className}`}>
        {/*
        <div>
          <button data-tooltip-id="my-tooltip-1"
            className={styles.resyncButton}
            onClick={() => handleResync(data?.contract_id)}
          >
            <img
              src="/images/refresh-icon.svg"
              alt="resync"
              width={15}
              height={15}
              className={styles.refreshIcon}
            />
          </button>
          <ReactTooltip
            id="my-tooltip-1"
            place="right"
            content="Resync Blockchain Data"
          />          
        </div>     
      */}  
      {/* <p className={styles.descText}>{potentialEarningTitle}:</p> */}
      <div className={styles.potentialContainer}>
        {/* <h4 className={`${styles.potentialEarning} ${styles.titleText}`}>
          {potentialEarning}
        </h4> */}
        <div className={styles.cardContentCont}>
          <div>
            <p className={styles.descText}>
              {" "}
              Amount:
            </p>
            <h4 className={`${styles.earningText} ${styles.titleText}`}>
              {data?.quantity.toString()} BPTS{" "}
            </h4>
          </div>
          <div>
            <p className={styles.earningsLable}>Expected Reward:</p>
            <p className={styles.earningsValue}>
              { (_contractStatus === "8") ?
                0 : Math.floor((data?.quantity || 0) * 0.01)
              } TXP
            </p>
          </div>
          {/*days > 0 &&
            data?.status?.toString() !== "8" &&
            data?.status?.toString() !== "9" && (
              <div>
                <p className={styles.descText}>{durationTitle}</p>
                <h4 className={`${styles.duration} ${styles.titleText}`}>
                  {days} days
                </h4>
              </div>
            )*/}
              <div>
                <p className={styles.descText}>{durationTitle}</p>
                <h4 className={`${styles.duration} ${styles.titleText}`}>
                  <Countdown date={Date.now() + msTillEnd} />
                </h4>
              </div>
        </div>       
        <CustomButton
          variant={isBtnDisabled ? "outlined" : "contained"}
          // onClick={() =>
          //   (data &&
          //     _contractStatus === "2" &&
          //     handleDeposit(
          //       data?.quantity.toString(),
          //       data?.asset_name,
          //       data?.policy_id,
          //       data?.contract_id
          //     )) ||
          //   _contractStatus === "4" ||
          //   (_contractStatus === "2" &&
          //     depositbtnOpened &&
          //     handleClose(data?.contract_id))
          // }
          onClick={() => onClickHandler(_contractStatus)}
          disabled={isBtnDisabled}
        >
          {/* {isBtnDisabled ? "Deposited" : "Deposit"} */}
          {btnTextHandler(_contractStatus)}
        </CustomButton>
      </div>
    </div>
  );
};

export default EarningCard;
