import { create } from "zustand";

import { ConnectWalletStore } from "../types/ConnectWallet";

export const useConnectWalletStore = create<ConnectWalletStore>()((set) => ({
  walletAddress: "",
  setWalletAddress: (_walletAddress: string) =>
    set({ walletAddress: _walletAddress }),
  connected: false,
  setConnected: (_connected: boolean) => set({ connected: _connected }),
}));

export default useConnectWalletStore;
