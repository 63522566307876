import React from "react";
import { ConnectedWallet } from "./Connection";
import * as wallet from "@marlowe.io/wallet";
import {
  BrowserRuntimeLifecycleOptions,
  mkRuntimeLifecycle,
} from "@marlowe.io/runtime-lifecycle/browser";
// import DisplayLocked from "./DisplayLocked";
// import Instruction from "./Instruction";
import { useEffect, useState } from "react";

/*
可以用的 Runtime URL
官方 - https://marlowe-runtime-preprod-web.scdev.aws.iohkdev.io
Demeter - https://marlowe-preprod-patch6-rt-webserver-absorbed-customer-6c15c9.us1.demeter.run
Walter - https://marlowe.aisw.in
本地 - http://192.168.3.13:3780
*/

const runtimeURL = process.env.REACT_APP_RUNTIME_URL;

const App = () => {
  const selectedWalletExtensionName = localStorage.getItem("walletProvider");
  console.log('test selectedWalletExtensionName',selectedWalletExtensionName);
  const [isConnected, setIsConnected] = useState<boolean>(
    selectedWalletExtensionName ? true : false
  );

  const setAndShowToast = (title: string, message: React.ReactNode) => {
    //const newToast = { id: new Date().getTime(), title, message };
    //setToasts(prevToasts => [...prevToasts, newToast]);
  };
  return (
    <div>
      {/* <h1>Hello, connect your wallet and try lockdrop</h1> */}
      <ConnectedWallet
        onConnect={() => setIsConnected(true)}
        onDisconnect={() => {
          setIsConnected(false);
        }}
        runtimeURL={runtimeURL}
      />
      {/* <p></p> */}
      {/* {isConnected ? <DisplayLocked /> : <Instruction />} */}
    </div>
  );
};

export default App;
