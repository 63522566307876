import {
  Contract,
  Party,
  Timeout,
  Constant,
  PolicyId,
  TokenName,
} from "@marlowe.io/language-core-v1";

/*
本合約的原始 Marlowe 語法範例

When
    [Case
        (Deposit
            (Address "addr_test1qp3k8ky0tp2e2sklcdn2sc5a26w344asllwlwegjzl8xyg28c8tr4rmyyppd9jw8zwncx793tkrxpuwxcjkpqnfn9x8sn35n9z")
            (Address "addr_test1qp3k8ky0tp2e2sklcdn2sc5a26w344asllwlwegjzl8xyg28c8tr4rmyyppd9jw8zwncx793tkrxpuwxcjkpqnfn9x8sn35n9z")
            (Token "34ebabe225e9d01147a423c8bc64a84c73a08ff10f8327a59821c0b9" "NativeTestToken")
            (Constant 10000)
        )
        (When
            []
            (TimeParam "timeout_expire")
            Close 
        )]
    (TimeParam "timeout_deposit")
    Close 

*/

function mkLockdropDemo(
  bintAmount: Constant, //lockdrop amount integer
  user: Party, //wallet address
  tokenPolicy: PolicyId,
  tokenAssetname: TokenName, // 不需要hex
  unlockTimeout: Timeout,//哪時到期
  depositTimeout: Timeout //時間內要存進來，30分鐘內
) {
  const simpleLockdropContract: Contract = {
    when: [
      {
        then: {
          when: [],
          timeout_continuation: "close",
          timeout: unlockTimeout, //big int
        },
        case: {
          party: user,//contract address / role token 
          of_token: {
            token_name: tokenAssetname,
            currency_symbol: tokenPolicy,
          },
          into_account: user,
          deposits: bintAmount,
        },
      },
    ],
    timeout_continuation: "close",
    timeout: depositTimeout,
  };

  return simpleLockdropContract;
}

export default mkLockdropDemo;
